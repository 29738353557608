<template>
	<div :id="id" class="my-modal">
		<transition v-if="show" name="modal">
			<div class="modal-mask">
				<div class="modal-wrapper" @click="close">
					<div class="modal-container">
							<a href="#" class="modal-close" @click.prevent="$emit('close')"><i class="lni-close"></i></a>
						<h4 class="modal-header">
							<slot name="header"></slot>
						</h4>
						<div class="modal-body my-4">
							<slot></slot>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default() {
					return false
				}
			},
			id: {
				type: String,
				default: ''
			}
		},

		methods: {
			close(e) {
				if (e.target.className === 'modal-wrapper') {
					this.$emit('close');
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
.my-modal {
	.modal-mask {
		position: fixed;
		z-index: 999999999999;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .7);
		display: table;
		transition: opacity .3s ease;
	}

	.modal-wrapper {
		display: table-cell;
		vertical-align: top;
	}

	.modal-container {
		width: 556px;
		margin: 3rem auto;
		padding: 20px;
		background-color: #fff;
		border-radius: 2px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
		transition: all .3s ease;
		

		@media(max-width: 768px) {
			width: 90%;
		}
	}

	.modal-header {
		padding: 0;
		border-bottom: none;
		display: block;
	}

	.modal-close {
    float: right;
    color: #ea4335;
    font-size: 16px;
	}

	.modal-body {
		margin: 0;
		overflow-y: auto;
		max-height: 70vh;
		padding: 0;
	}

	/*
	* The following styles are auto-applied to elements with
	* transition="modal" when their visibility is toggled
	* by Vue.js.
	*
	* You can easily play with the modal transition by editing
	* these styles.
	*/

	.modal-enter {
		opacity: 0;
	}

	.modal-leave-active {
		opacity: 0;
	}

	.modal-enter .modal-container,
	.modal-leave-active .modal-container {
		transform: scale(1.1);
	}
}
</style>