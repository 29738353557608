import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/products/sms',
    name: 'products.sms',
    component: () => import(/* webpackChunkName: "products.sms" */ '../views/products/Sms.vue')
  },
  {
    path: '/products/voice',
    name: 'products.voice',
    component: () => import(/* webpackChunkName: "products.voice" */ '../views/products/Voice.vue')
  },
  {
    path: '/products/ussd',
    name: 'products.ussd',
    component: () => import(/* webpackChunkName: "products.ussd" */ '../views/products/USSD.vue')
  },
  {
    path: '/pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue'),
    children: [
      {
        path: '',
        name: 'pricing.sms',
        component: () => import(/* webpackChunkName: "pricing.sms" */ '../views/pricing/Sms.vue')
      },
      {
        path: 'voice',
        name: 'pricing.voice',
        component: () => import(/* webpackChunkName: "pricing.voice" */ '../views/pricing/Voice.vue')
      },
      {
        path: 'airtime',
        name: 'pricing.airtime',
        component: () => import(/* webpackChunkName: "pricing.airtime" */ '../views/pricing/Airtime.vue')
      },
      {
        path: 'ussd',
        name: 'pricing.ussd',
        component: () => import(/* webpackChunkName: "pricing.ussd" */ '../views/pricing/Ussd.vue')
      }
    ]
  },
  {
    path: '/news',
    name: 'news.index',
    component: () => import(/* webpackChunkName: "news.index" */ '../views/news/Index.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/AboutUs.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUs.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/FAQs.vue')
  },
  {
    path: '/*',
    name: 'errors.404',
    component: () => import(/* webpackChunkName: "errors.404" */ '../views/errors/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
