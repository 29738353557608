import './assets/scss/main.scss'

import 'animate.css/animate.css'

import 'font-awesome/css/font-awesome.css'

import '@icon/themify-icons/themify-icons.css'

import 'owl-carousel/owl-carousel/owl.carousel.css'

// Theme
import './assets/theme/css/base.css'
import './assets/theme/css/style.css'
import './assets/theme/css/responsive.css'
import './assets/theme/css/shortcodes.css'

// Javascript
window.$ = require('jquery')
require('bootstrap')
require('popper.js')
require('./assets/js/custom')