<template>
  <div id="ht-preloader">
    <div class="loader clear-loader">
      <div class="loader-text">Loading</div>
      <div class="loader-dots">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
