<template>
  <div id="app" class="page-wrapper">
    <app-header />
    
    <div class="custom-page-content">
      <router-view />
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'

export default {
  components: {
    AppHeader, AppFooter
  }
}
</script>