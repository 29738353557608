import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// components
import Modal from './components/Modal';
import Loading from './components/Loading';

require('./theme')

Vue.config.productionTip = false

// register global components
Vue.component('Loading', Loading)
Vue.component('Modal', Modal)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
