<template>
  <div>
    <header id="site-header" class="header">
      <div id="header-wrap">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <!-- Navbar -->
              <nav class="navbar navbar-expand-lg">
                <router-link class="navbar-brand logo text-logo" to="/">
                  RIZIKISMS
                </router-link>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div id="navbarNavDropdown" class="collapse navbar-collapse">
                  <ul class="navbar-nav ml-auto mr-auto">
                    <li class="nav-item dropdown" data-toggle="hover">
                      <a
                        class="nav-link actives dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >Products</a
                      >
                      <div class="dropdown-menu">
                        <ul class="list-unstyled">
                          <li><router-link :to="{ name: 'products.sms' }">SMS</router-link></li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" :to="{ name: 'pricing.sms' }">Pricing</router-link>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" :href="developer_docs_url" target="_blank">Developers</a>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" :to="{ name: 'about-us' }">About Us</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" :to="{ name: 'contact-us' }">Contact Us</router-link>
                    </li>
                  </ul>
                </div>
                <div
                  class="right-nav align-items-center d-flex justify-content-end list-inline"
                >
                  <a class="btn btn-white btn-sm" target="_blank" :href="`${system_url}/login`">Login</a>
                  <a class="btn btn-white bg-primary text-white btn-sm" target="_blank" :href="`${system_url}/register`">Create Account</a>
                  <div class="search">
                    
                  </div>
                  <a href="#" class="ht-nav-toggle"><span></span></a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

    <nav id="ht-main-nav">
      <a href="#" class="ht-nav-toggle active"><span></span></a>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="mb-2"><a href="#" class="logo text-logo">RIZIKISMS</a></p>
            <p class="mb-5">
              We provide innovative customer-specific soft solutions around the company. Our platform revolves around effectively identifying and meeting customer needs through mobile engagement. 
            </p>
            <div class="form-info">
              <h4 class="title">Contact info</h4>
              <ul class="contact-info list-unstyled mt-4">
                <li class="mb-4">
                  <i class="flaticon-location"></i><span>Address:</span>
                  <p>Ground Floor, Utalii House, Utalii Street, Nairobi, Kenya.</p>
                  <p>PO Box 4058, 00506, Nairobi</p>
                </li>
                <li class="mb-4">
                  <i class="flaticon-call"></i><span>Phone:</span
                  ><a href="tel:+254718844831">+254 718 844 831</a>
                </li>
                <li>
                  <i class="flaticon-email"></i><span>Email</span
                  ><a href="mailto:support@rizikisms.co.ke">support@rizikisms.co.ke</a>
                </li>
              </ul>
            </div>
            <div class="social-icons social-colored mt-5">
              <ul class="list-inline">
                <li class="mb-2 social-facebook">
                  <a href="#"><i class="fa fa-facebook-f"></i></a>
                </li>
                <li class="mb-2 social-twitter">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="mb-2 social-linkedin">
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from "jquery";

export default {

  data() {
    return {
      yar: false
    }
  },

  computed: {
    developer_docs_url() {
      return process.env.VUE_APP_DEVELOPER_DOCS_URL
    },

    system_url() {
      return process.env.VUE_APP_SYSTEM_URL
    }
  },
  mounted() {
    this.initJs();
  },

  methods: {
    initJs() {
      let searchActive = false;

      if ($(".search-form").length) {
        const searchForm = $(".search-form");
        const searchInput = $(".search-input");
        const searchButton = $(".search-button");

        searchButton.on("click", (event) => {
          event.stopPropagation();

          if (!searchActive) {
            searchForm.addClass("active");
            searchActive = true;
            searchInput.focus();

            $(document).one("click", function closeForm(e) {
              if ($(e.target).hasClass("search-input")) {
                $(document).one("click", closeForm);
              } else {
                searchForm.removeClass("active");
                searchActive = false;
              }
            });
          } else {
            searchForm.removeClass("active");
            searchActive = false;
          }
        });
      }

      $(window).on("scroll", () => {
        if ($(window).scrollTop() >= 100) {
          $("#header-wrap").addClass("fixed-header");
        } else {
          $("#header-wrap").removeClass("fixed-header");
        }
      });

      $(".ht-nav-toggle").on("click", (event) => {
        event.preventDefault();
        if ($("body").hasClass("menu-show")) {
          $("body").removeClass("menu-show");
          $("#ht-main-nav > .ht-nav-toggle").removeClass("show");
        } else {
          $("body").addClass("menu-show");
          setTimeout(() => {
            $("#ht-main-nav > .ht-nav-toggle").addClass("show");
          }, 900);
        }
      });
    },
  }
};
</script>
